<template>
  <div class="ogLoader">
    <div class="ogLoader-loader"></div>
    <svg class="ogLoader-logo" width="401" height="204" viewBox="0 0 401 204" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M400.039 129.842V155.708H324.378V88.5576L400.289 88.8125V112.895H353.642V129.969H400.039V129.842Z" fill="white" />
      <path d="M398.038 50.0758V71.1002H326.879V50.0758H398.038ZM400.414 47.7822H324.628V73.5211H400.414V47.7822Z" fill="white" />
      <path d="M256.347 73.5216L236.338 47.6553H213.577V73.5216H256.347Z" fill="white" />
      <path d="M308.122 47.6553H278.983V73.5216H308.122V47.6553Z" fill="white" />
      <path d="M278.983 88.5576V102.701L267.978 88.5576H213.577V155.836H242.716V102.701L284.861 155.836H308.122V88.5576H278.983Z" fill="white" />
      <path
        d="M193.691 73.5215C191.69 70.2085 189.439 67.023 186.813 64.0924C182.186 58.8681 176.433 54.6633 169.93 51.4778C163.302 48.4197 156.048 46.8906 147.919 46.8906C140.041 46.8906 132.787 48.4197 126.159 51.3503C119.531 54.281 113.778 58.3585 108.901 63.4553C105.899 66.6408 103.398 69.9537 101.272 73.6489H193.691V73.5215Z"
        fill="white"
      />
      <path
        d="M169.679 88.5566C169.929 89.3212 170.179 90.0857 170.43 90.8502C171.43 94.1631 171.93 97.7309 171.93 101.426C171.93 104.994 171.43 108.434 170.555 111.875C169.554 115.315 168.179 118.373 166.178 121.176C164.177 123.979 161.675 126.146 158.674 127.802C155.547 129.459 151.921 130.223 147.794 130.223C143.667 130.223 140.04 129.459 136.914 127.802C133.912 126.273 131.411 124.107 129.41 121.431C127.409 118.755 125.908 115.697 124.908 112.257C123.907 108.816 123.407 105.376 123.407 101.681C123.407 98.1132 123.907 94.6728 124.783 91.2325C125.033 90.3405 125.283 89.4486 125.658 88.5566H95.1435C94.1431 92.7615 93.6428 96.9664 93.6428 101.426C93.6428 108.434 94.8934 115.187 97.3946 121.813C99.8958 128.312 103.523 134.301 108.275 139.525C113.027 144.749 118.655 148.954 125.283 152.012C131.911 155.07 139.165 156.599 147.294 156.599C155.297 156.599 162.551 155.07 169.179 152.139C175.807 149.081 181.435 145.131 186.312 139.907C191.19 134.81 194.941 128.949 197.568 122.45C200.194 115.952 201.57 109.199 201.57 102.063C201.57 97.4761 200.944 93.0164 199.944 88.6841H169.679V88.5566Z"
        fill="white"
      />
      <path
        d="M188.939 188.71C188.939 186.671 189.314 184.76 190.064 183.104C190.815 181.32 191.94 179.791 193.316 178.516C194.691 177.242 196.442 176.223 198.568 175.458C200.694 174.694 203.07 174.312 205.697 174.312C206.697 174.312 207.698 174.312 208.573 174.439C209.574 174.566 210.574 174.694 211.449 174.949C212.45 175.203 213.325 175.458 214.201 175.841C215.076 176.223 215.952 176.605 216.827 176.987L214.201 181.32C213.701 181.065 213.2 180.81 212.575 180.555C211.95 180.3 211.324 180.173 210.574 179.918C209.824 179.791 209.073 179.663 208.323 179.536C207.573 179.408 206.822 179.408 206.072 179.408C204.321 179.408 202.695 179.663 201.32 180.045C199.944 180.555 198.818 181.192 197.818 181.957C196.817 182.721 196.192 183.741 195.567 184.887C195.067 186.034 194.817 187.308 194.817 188.71C194.817 190.112 195.067 191.386 195.567 192.66C196.067 193.807 196.817 194.826 197.818 195.718C198.818 196.61 199.944 197.247 201.32 197.757C202.695 198.267 204.196 198.521 205.947 198.521C207.322 198.521 208.573 198.394 209.699 198.012C210.824 197.757 211.825 197.247 212.7 196.737C213.575 196.228 214.201 195.463 214.701 194.699C215.201 193.934 215.576 192.915 215.701 192.023H205.947V187.308H220.829C221.079 188.71 221.204 190.239 221.079 191.641C220.954 193.042 220.704 194.317 220.204 195.591C219.703 196.865 218.953 197.884 218.078 198.904C217.202 199.923 216.077 200.815 214.951 201.579C213.701 202.344 212.325 202.854 210.699 203.236C209.073 203.618 207.448 203.873 205.572 203.873C202.945 203.873 200.569 203.491 198.568 202.726C196.442 201.962 194.691 200.942 193.316 199.541C191.94 198.139 190.815 196.61 190.064 194.826C189.314 192.66 188.939 190.749 188.939 188.71Z"
        fill="white"
      />
      <path
        d="M237.587 174.693H251.843C253.969 174.693 255.845 174.948 257.471 175.33C259.097 175.84 260.347 176.477 261.348 177.369C262.348 178.261 263.224 179.28 263.724 180.555C264.224 181.829 264.474 183.231 264.474 184.76C264.474 185.779 264.349 186.798 264.099 187.818C263.849 188.837 263.474 189.602 262.974 190.493C262.474 191.258 261.848 192.023 260.973 192.66C260.222 193.297 259.222 193.806 258.221 194.189L264.349 203.363H257.346L252.094 195.208H251.969H243.339V203.363H237.587V174.693ZM251.969 190.111C253.094 190.111 253.969 189.984 254.845 189.729C255.595 189.474 256.346 189.092 256.846 188.71C257.346 188.2 257.846 187.69 258.096 187.053C258.347 186.416 258.472 185.652 258.472 184.887C258.472 183.231 257.971 182.084 256.846 181.192C255.72 180.3 254.095 179.918 251.969 179.918H243.214V190.366H251.969V190.111Z"
        fill="white"
      />
      <path
        d="M279.482 188.837C279.482 186.671 279.858 184.76 280.608 182.849C281.358 181.065 282.484 179.536 283.86 178.262C285.235 176.987 286.986 175.968 288.862 175.331C290.863 174.566 292.989 174.312 295.365 174.312C297.741 174.312 299.867 174.694 301.868 175.331C303.869 176.095 305.495 176.987 306.996 178.262C308.371 179.536 309.497 181.065 310.247 182.849C310.998 184.633 311.373 186.671 311.373 188.837C311.373 191.004 310.998 193.042 310.247 194.826C309.497 196.61 308.371 198.139 306.996 199.541C305.62 200.815 303.869 201.834 301.868 202.599C299.867 203.363 297.741 203.746 295.365 203.746C292.989 203.746 290.738 203.363 288.862 202.599C286.861 201.834 285.235 200.815 283.86 199.541C282.484 198.267 281.358 196.737 280.608 194.826C279.858 193.042 279.482 191.131 279.482 188.837ZM285.235 188.837C285.235 190.366 285.485 191.641 285.986 192.787C286.486 193.934 287.236 194.954 288.112 195.846C288.987 196.737 290.113 197.375 291.363 197.757C292.614 198.267 293.989 198.394 295.49 198.394C296.991 198.394 298.366 198.139 299.617 197.757C300.868 197.247 301.993 196.61 302.869 195.846C303.744 194.954 304.494 194.062 304.995 192.787C305.495 191.641 305.745 190.239 305.745 188.837C305.745 187.308 305.495 186.034 304.995 184.887C304.494 183.741 303.744 182.721 302.869 181.957C301.993 181.192 300.868 180.555 299.617 180.045C298.366 179.663 296.991 179.408 295.49 179.408C293.989 179.408 292.614 179.663 291.363 180.045C290.113 180.428 288.987 181.065 288.112 181.957C287.236 182.721 286.486 183.741 285.986 184.887C285.485 186.162 285.235 187.436 285.235 188.837Z"
        fill="white"
      />
      <path
        d="M327.505 174.693H333.258V190.493C333.258 191.768 333.383 192.914 333.758 193.934C334.133 194.953 334.634 195.845 335.259 196.482C336.009 197.119 336.76 197.756 337.76 198.011C338.761 198.394 340.011 198.521 341.262 198.521C342.637 198.521 343.763 198.394 344.763 198.011C345.764 197.629 346.639 197.119 347.39 196.482C348.14 195.845 348.64 194.953 348.891 193.934C349.266 192.914 349.391 191.768 349.391 190.493V174.693H355.144V191.131C355.144 193.042 354.893 194.698 354.268 196.227C353.643 197.756 352.767 199.031 351.517 200.177C350.391 201.324 348.89 202.089 347.14 202.726C345.389 203.363 343.513 203.618 341.262 203.618C339.011 203.618 337.135 203.363 335.384 202.726C333.633 202.089 332.257 201.324 331.007 200.177C329.881 199.031 328.881 197.756 328.256 196.227C327.63 194.698 327.38 193.042 327.38 191.131V174.693H327.505Z"
        fill="white"
      />
      <path
        d="M372.776 174.693H387.533C389.659 174.693 391.535 174.948 393.16 175.458C394.786 175.968 396.037 176.605 397.037 177.497C398.038 178.389 398.913 179.535 399.413 180.81C399.914 182.084 400.164 183.613 400.164 185.142C400.164 186.671 399.914 188.072 399.413 189.347C398.913 190.621 398.163 191.768 397.162 192.66C396.162 193.552 394.786 194.316 393.285 194.826C391.66 195.335 389.784 195.59 387.658 195.59H378.528V203.235H372.776V174.693ZM387.658 190.621C388.783 190.621 389.659 190.493 390.534 190.239C391.284 189.984 392.035 189.602 392.535 189.092C393.035 188.582 393.536 188.072 393.786 187.308C394.036 186.671 394.161 185.906 394.161 185.014C394.161 183.358 393.661 181.956 392.535 181.064C391.41 180.172 389.784 179.663 387.658 179.663H378.403V190.621H387.658Z"
        fill="white"
      />
      <path
        d="M184.187 88.5571L197.443 102.064L101.147 200.177L4.72592 102.064L101.147 3.82261L169.43 73.5215H173.307L101.147 0L0.974121 102.064L101.147 204L201.195 102.064L188.064 88.5571H184.187Z"
        fill="white"
      />
    </svg>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.ogLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  height: 100vh;
  width: 160px;
  &-loader {
    position: absolute;
    border: 8px solid rgba(0, 0, 0, 0.1);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border-right-color: $primary_color;
    right: calc(50% - 80px);
    top: calc(50% - 80px);
    animation: spin 1s linear infinite;
  }
  &-logo {
    height: 120px;
    width: 120px;
    font-size: 130px;
    position: relative;
    * {
      fill: black;
    }
  }
}
</style>

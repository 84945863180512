<template>
  <div id="app">
    <UpdateAvailable />
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UpdateAvailable from "./components/app/UpdateAvailable.vue";

export default {
  components: { UpdateAvailable },
  data: function () {
    return {
      updates: null,
    };
  },
  methods: {
    ...mapActions(["startApp"]),
    navigating: function () {
      const myBody = document.querySelector("body");
      myBody.classList.remove("onmenu");
    },
  },
  mounted: async function () {
    await this.startApp();
  },
  watch: {
    $route(to, from) {
      this.navigating();
    },
  },
};
</script>

<style lang="scss">
/* width and height*/
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}
body.starting {
  @include loading;
  * {
    display: none;
  }
}
</style>

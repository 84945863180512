import ialert from "./ialert.js";
import "./ialert.icons.scss";
import "./ialert.scss";
import "./ialert.custom.scss";

let currentAlerts = [];

export default {
  install(Vue, options) {
    Vue.mixin({
      data: function() {
        return {
          ialert: {
            go: function(options) {
              return this.open(options);
            },
            show: function(options) {
              return this.open(options);
            },
            close: function(alertID, options = {}) {
              options.currentAlerts = currentAlerts;
              ialert.closeAll(alertID, options);
            },
            closeAll: function(options = {}) {
              options.currentAlerts = currentAlerts;
              ialert.closeAll("all", options);
            },
            open: function(options) {
              return new Promise(function(resolve, reject) {
                const alertID = ialert.getID();
                let alertHtml = ialert.getCustomAlert(alertID, options);

                if (options?.unique) {
                  options.currentAlerts = currentAlerts;
                  ialert.closeAll(alertID, options);
                }

                if (options?.type === "loader") {
                  alertHtml = ialert.getCustomLoader(alertID, options);
                }

                const myBody = document.querySelector("body");
                const myDivAlert = document.createElement("div");
                myDivAlert.id = `ialert-${alertID}`;
                myDivAlert.classList.add("ialert");
                myDivAlert.innerHTML = alertHtml;
                document.body.appendChild(myDivAlert);
                myBody.classList.add(`ialert-${alertID}`);
                currentAlerts[alertID] = options;

                addListeners(document.querySelectorAll(`#ialert-${alertID} *[action]`));

                function addListeners(buttons) {
                  buttons.forEach((button) => {
                    button.addEventListener("click", (event) => {
                      const action = event.srcElement.getAttribute("action");
                      const content = document.querySelector(`#ialert-${alertID}`);
                      resolve({ action, id: alertID, content });
                      ialert.destroy(alertID, options);
                    });
                  });
                }
              });
            },
          },
        };
      },
    });
  },
};

const routes = [
	{
		path: '/error',
		name: 'Error',
		component: () => import('../views/Error.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import('../views/404.vue'),
	},
];

export default routes;

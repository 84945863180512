<template>
  <div class="preload">
    <picture class="preload-bg">
      <source :srcset="$store.state.preload || ''" media="(min-width: 768px)" alt="Code image" />
      <img :src="$store.state.image || ''" alt="Code image" />
    </picture>
    <div class="preload-logo">
      <svg width="530" viewBox="0 0 530 266" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M132.93 265.738L0 132.93L132.93 0L265.861 132.93L132.93 265.738ZM5.04176 132.93L132.93 260.819L260.819 132.93L132.93 5.04176L5.04176 132.93Z"
          fill="white"
        />
        <path
          d="M194.292 203.885C183.594 203.885 173.879 201.918 165.148 197.86C156.418 193.802 148.916 188.391 142.645 181.628C136.374 174.864 131.578 167.117 128.135 158.632C124.814 150.147 123.093 141.294 123.093 132.194C123.093 122.848 124.937 113.871 128.503 105.386C132.07 96.9014 137.111 89.4003 143.506 82.6369C149.9 75.9965 157.524 70.7088 166.378 66.8968C175.232 63.0847 184.824 61.1172 195.276 61.1172C205.974 61.1172 215.689 63.0847 224.42 67.1427C233.151 71.2007 240.652 76.6114 246.8 83.4977C253.072 90.384 257.868 98.1311 261.188 106.616C264.508 115.101 266.23 123.955 266.23 132.932C266.23 142.154 264.508 151.008 260.942 159.493C257.376 167.978 252.457 175.479 246.063 182.243C239.668 188.883 232.044 194.171 223.313 198.106C214.582 201.918 204.868 203.885 194.292 203.885ZM162.32 132.44C162.32 137.236 162.935 141.785 164.288 146.212C165.64 150.639 167.608 154.574 170.19 158.14C172.896 161.707 176.216 164.412 180.274 166.502C184.332 168.593 189.128 169.577 194.661 169.577C200.195 169.577 204.991 168.47 209.172 166.379C213.23 164.289 216.673 161.338 219.255 157.772C221.838 154.082 223.805 150.147 225.035 145.72C226.264 141.294 226.879 136.744 226.879 132.071C226.879 127.275 226.264 122.725 224.912 118.421C223.559 113.994 221.592 110.059 219.009 106.616C216.304 103.173 212.984 100.345 208.926 98.3771C204.868 96.4095 200.072 95.4258 194.661 95.4258C189.128 95.4258 184.332 96.5325 180.274 98.623C176.216 100.713 172.896 103.542 170.19 107.108C167.485 110.674 165.64 114.609 164.288 119.036C162.935 123.34 162.32 127.89 162.32 132.44Z"
          fill="white"
        />
        <path d="M320.828 133.669V202.901H282.093V62.1006H312.343L368.909 133.669V62.1006H407.645V202.901H376.902L320.828 133.669Z" fill="white" />
        <path d="M529.343 202.178V169H467V146L529.589 146.227V115H479.5H467V106.5V98H429V202.178H529.343Z" fill="white" />
        <path d="M530 115V95H71V115H530Z" fill="url(#paint0_linear_1998:4761)" />
        <path :d="`M429 62H${lineWidth.ePercent}V95H429V62Z`" fill="white" />
        <path d="M526 65.0742V92H432.074V65.0742H526ZM529.589 62H429V95H529.589V62Z" fill="white" />
        <path
          d="M249.506 245.816C249.506 243.234 249.998 240.775 250.982 238.438C251.965 236.102 253.441 234.134 255.285 232.536C257.13 230.814 259.466 229.584 262.295 228.601C265 227.617 268.197 227.125 271.763 227.125C272.993 227.125 274.346 227.248 275.575 227.371C276.928 227.494 278.158 227.74 279.388 227.986C280.617 228.232 281.847 228.601 283.077 229.093C284.306 229.584 285.413 230.076 286.52 230.691L283.077 236.348C282.462 235.979 281.724 235.61 280.863 235.364C280.002 234.995 279.142 234.749 278.158 234.626C277.174 234.38 276.19 234.257 275.207 234.134C274.223 234.011 273.239 234.011 272.132 234.011C269.796 234.011 267.705 234.257 265.861 234.872C264.016 235.487 262.418 236.348 261.188 237.332C259.958 238.438 258.975 239.668 258.237 241.144C257.622 242.619 257.253 244.218 257.253 246.062C257.253 247.907 257.622 249.628 258.36 251.104C259.098 252.703 260.081 253.932 261.311 255.039C262.541 256.146 264.139 257.007 265.984 257.622C267.828 258.236 269.796 258.605 272.132 258.605C273.977 258.605 275.575 258.359 277.051 257.99C278.527 257.622 279.879 257.007 280.986 256.269C282.093 255.531 282.954 254.67 283.691 253.564C284.429 252.457 284.798 251.35 285.044 249.997H272.009V243.726H291.807C292.176 245.693 292.299 247.538 292.176 249.26C292.053 251.104 291.562 252.703 290.947 254.301C290.332 255.9 289.348 257.376 288.118 258.728C286.889 260.081 285.536 261.188 283.937 262.171C282.339 263.155 280.494 263.893 278.404 264.385C276.313 264.877 274.1 265.123 271.517 265.123C268.074 265.123 265 264.631 262.172 263.647C259.466 262.663 257.13 261.188 255.162 259.466C253.318 257.745 251.842 255.654 250.736 253.318C249.998 250.981 249.506 248.522 249.506 245.816Z"
          fill="white"
        />
        <path
          d="M314.065 227.496H333.002C335.831 227.496 338.29 227.742 340.38 228.357C342.471 228.972 344.193 229.833 345.545 230.939C346.898 232.046 348.005 233.399 348.619 235.12C349.234 236.719 349.603 238.563 349.603 240.654C349.603 242.007 349.48 243.359 349.111 244.589C348.742 245.819 348.251 246.925 347.636 248.032C346.898 249.139 346.037 250 345.053 250.86C343.947 251.721 342.717 252.336 341.364 252.951L349.48 264.879H340.258L333.248 254.304H333.002H321.566V264.879H314.065V227.496ZM333.125 247.663C334.601 247.663 335.831 247.54 336.937 247.171C338.044 246.802 338.905 246.31 339.643 245.819C340.38 245.204 340.872 244.466 341.241 243.605C341.61 242.744 341.733 241.761 341.733 240.654C341.733 238.563 340.995 236.965 339.643 235.858C338.167 234.751 336.077 234.136 333.125 234.136H321.566V247.663H333.125Z"
          fill="white"
        />
        <path
          d="M369.647 246.063C369.647 243.235 370.139 240.653 371.246 238.316C372.23 235.98 373.705 234.012 375.55 232.291C377.395 230.569 379.608 229.339 382.313 228.356C384.896 227.495 387.847 227.003 391.044 227.003C394.241 227.003 397.07 227.495 399.775 228.356C402.357 229.216 404.694 230.569 406.538 232.291C408.383 234.012 409.859 235.98 410.842 238.316C411.826 240.653 412.441 243.235 412.441 246.063C412.441 248.892 411.949 251.474 410.842 253.81C409.859 256.147 408.383 258.237 406.538 259.836C404.694 261.557 402.48 262.91 399.775 263.771C397.193 264.755 394.241 265.247 391.044 265.247C387.847 265.247 385.019 264.755 382.313 263.771C379.731 262.787 377.518 261.557 375.55 259.836C373.705 258.114 372.23 256.147 371.246 253.81C370.139 251.474 369.647 248.892 369.647 246.063ZM377.272 246.063C377.272 248.031 377.641 249.752 378.255 251.228C378.993 252.827 379.854 254.056 381.084 255.163C382.313 256.27 383.789 257.131 385.388 257.622C387.109 258.237 388.831 258.483 390.798 258.483C392.766 258.483 394.61 258.237 396.209 257.622C397.931 257.008 399.283 256.147 400.513 255.163C401.743 254.056 402.726 252.704 403.341 251.228C404.079 249.629 404.448 247.908 404.448 246.063C404.448 244.096 404.079 242.374 403.341 240.899C402.603 239.423 401.743 238.07 400.513 237.086C399.283 235.98 397.808 235.242 396.209 234.627C394.487 234.012 392.766 233.766 390.798 233.766C388.831 233.766 386.986 234.012 385.388 234.627C383.666 235.242 382.313 235.98 381.084 237.086C379.854 238.193 378.87 239.423 378.255 240.899C377.641 242.374 377.272 244.096 377.272 246.063Z"
          fill="white"
        />
        <path
          d="M433.346 227.496H440.847V248.032C440.847 249.754 441.093 251.229 441.585 252.582C442.077 253.935 442.691 255.041 443.675 255.902C444.536 256.763 445.766 257.501 447.118 257.993C448.471 258.484 450.07 258.73 451.791 258.73C453.513 258.73 455.111 258.484 456.464 257.993C457.817 257.501 458.923 256.886 459.907 255.902C460.768 255.041 461.506 253.935 461.998 252.582C462.49 251.229 462.736 249.754 462.736 248.032V227.496H470.237V248.893C470.237 251.352 469.868 253.566 469.007 255.533C468.146 257.501 467.039 259.222 465.441 260.698C463.842 262.174 461.998 263.28 459.661 264.018C457.325 264.756 454.742 265.125 451.914 265.125C448.963 265.125 446.381 264.756 444.167 264.018C441.831 263.28 439.986 262.174 438.387 260.698C436.789 259.222 435.559 257.501 434.821 255.533C433.961 253.566 433.592 251.352 433.592 248.893V227.496H433.346Z"
          fill="white"
        />
        <path
          d="M493.478 227.496H513.153C515.982 227.496 518.441 227.865 520.531 228.48C522.622 229.095 524.343 229.955 525.696 231.185C527.049 232.292 528.156 233.768 528.77 235.489C529.385 237.211 529.754 239.055 529.754 241.146C529.754 243.113 529.385 244.958 528.77 246.679C528.156 248.401 527.049 249.877 525.696 251.106C524.343 252.336 522.622 253.32 520.531 253.935C518.441 254.672 515.982 254.918 513.153 254.918H500.979V264.756H493.478V227.496ZM513.276 248.401C514.752 248.401 515.982 248.278 517.088 247.909C518.195 247.54 519.056 247.048 519.794 246.433C520.531 245.819 521.023 245.081 521.392 244.097C521.761 243.236 521.884 242.252 521.884 241.146C521.884 238.932 521.146 237.211 519.671 235.981C518.195 234.751 516.104 234.136 513.276 234.136H500.979V248.401H513.276Z"
          fill="white"
        />
        <linearGradient id="paint0_linear_1998:4761" :x1="lineWidth.barPercent" y1="95" x2="530" y2="95" gradientUnits="userSpaceOnUse">
          <stop stop-opacity="0" />
          <stop offset="0.128235" />
        </linearGradient>
      </svg>
    </div>
    <div class="preload-counter">
      <p>{{ loadingPercent }}%</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      progress: 0,
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
    };
  },
  methods: {
    load() {
      const step = this.loadTime / 100;
      this.interval = setInterval(() => {
        this.loadingPercent++;
        if (this.loadingPercent >= 100) {
          this.$store.state.viewLoaded = true;
          clearInterval(this.interval);
        }
      }, step);
    },
  },
  computed: {
    lineWidth() {
      const barPercent = 529 - this.loadingPercent * 4.6;
      const ePercent = 530 - this.loadingPercent * 4.3;
      return { barPercent, ePercent: ePercent <= 432 ? 432 : ePercent };
    },
  },
  created() {
    this.loadTime = ((performance.timeOrigin / 1000) % 60) * 100;
    this.load();
  },
};
</script>
<style lang="scss">
.preload {
  background: black;
  height: 100vh;
  overflow: hidden;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  &-logo {
    display: flex;
    justify-content: center;
    margin-top: -130px;
    svg {
      max-width: 80%;
    }
  }

  &-counter {
    font-family: $sec_font;
    font-size: 24px;
    line-height: 32px;
    color: white;
  }

  &-bg {
    position: absolute;
    z-index: -1;
    img {
      height: 100vh;
      width: 100vw;
      object-fit: cover;
      vertical-align: middle;
    }
  }

  @media (min-width: $tablet_width) {
    gap: 30px;
    &-logo {
      margin-top: 0%;
    }
  }
}
</style>

<template>
  <i class="iconic" :style="`${setSize()}`" v-if="name" v-html="`${myIcon(name)}`"></i>
</template>

<script>
import icons from "./icons";
export default {
  name: "iconic",
  props: {
    name: String,
    color: { type: String, default: "currentColor" },
    options: { type: Object },
    size: String,
  },
  data: function() {
    return {
      icons: icons,
    };
  },
  methods: {
    setSize: function() {
      if (this.size) {
        return `font-size:${this.size}`;
      }
    },
    evaluate: function(svg) {
      if (!svg) return;
      let elsvg = document.createElement("div");
      elsvg.innerHTML = svg;
      const nodes = elsvg.querySelectorAll("*");
      nodes.forEach((node) => {
        if (node.hasAttribute("fill") && node.getAttribute("fill") !== "none") {
          node.setAttribute("fill", this.color);
        }
        if (node.hasAttribute("stroke")) {
          node.setAttribute("stroke", this.color);
        }
      });
      const html = Array.prototype.reduce.call(
        nodes,
        function(html, node, count) {
          count++;
          if (count === 1) {
            html = node.outerHTML || node.nodeValue;
          }
          return html;
        },
        ""
      );
      return html;
    },
    myIcon: function(name) {
      const theicon = this.evaluate(icons(name));
      return theicon ?? "?";
    },
  },
};
</script>

<style lang="scss">
.iconic {
  font-style: normal;
  line-height: 1em;
  svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
  }
}
</style>

import axios from "axios";

axios.interceptors.request.use(function(config) {
  const ulang = localStorage.getItem(process.env.VUE_APP_LANG_KEY);
  const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  if (ulang) config.headers.common[process.env.VUE_APP_LANG_KEY] = ulang;
  if (token) config.headers.common[process.env.VUE_APP_TOKEN_NAME] = token;
  return config;
});

export default {
  get: (url, request) => axios.get(url, request),
  post: (url, request) => axios.post(url, request),
  patch: (url, request) => axios.patch(url, request),
  put: (url, request) => axios.put(url, request),
  delete: (url, request) => axios.delete(url, request),
};

const parseJwt = (nametkn) => {
  const token = localStorage.getItem(nametkn);
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
};

const ifAuthenticated = async (to, from, next) => {
  const user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
  if (user && user.name) {
    next();
  } else {
    localStorage.setItem("meta-nav", JSON.stringify(to.meta));
    next(to.meta.deniedTo ? to.meta.deniedTo : "/login");
  }
  return;
};

const ifNotAuthenticated = async (to, from, next) => {
  const nav = JSON.parse(localStorage.getItem("meta-nav"));
  const user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
  if (to.meta?.requireQuery && !to.query[to.meta.requireQuery]) {
    next(to.meta.deniedTo);
  } else if (user && user.name) {
    next(nav ? nav.approvedTo : "/");
  } else {
    next();
  }
  return;
};

module.exports = { ifAuthenticated, ifNotAuthenticated, parseJwt };

import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import api from "@/api";
import { parseJwt } from "../assets/js/session";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: null,
    user: parseJwt(process.env.VUE_APP_TOKEN_NAME),
    updates: null,
    loadingContent: false,
    viewLoaded: false,
    screen: window.screen,
    preload: "",
    image: "",
    screen: { width: window.innerWidth, height: window.innerHeight },
  },
  actions: {
    startApp: async function ({ state }) {
      const route = location.pathname.substring(1);
      state.loadingContent = true;
      const sedData = api.get(`${process.env.VUE_APP_API_HOST}?view=${route ? route : "home"}`);
      sedData.then((response) => {
        this.dispatch("apiReady", response);
        state.loadingContent = false;
      });
      sedData.catch((response) => this.dispatch("apiError", response));
    },
    apiReady: async function ({ state }, response) {
      state.preload = response.data.language["nav-preload-image"];
      state.image = response.data.language["nav-web"];
      state.locale = response.data.language;
      await this.dispatch("getLocale");
      await this.dispatch("appLoaded");
      this.dispatch("nativeEvents");
    },
    async updateLocal({ state }) {
      const route = location.pathname.substring(1);
      const { data } = await api.get(`${process.env.VUE_APP_API_HOST}?view=${route ? route : "home"}`);
      const { object, ...locale } = data.language;
      state.locale.object = { ...state.locale.object, ...object };
      state.locale = { ...state.locale, ...locale };
    },
    apiError: async function ({ commit }, response) {
      await this.dispatch("getLocale");
      await this.dispatch("appLoaded");
      this.dispatch("nativeEvents");
    },
    appLoaded: async function ({ commit }) {
      document.querySelector("body").classList.remove("starting");
    },
    updateUser: async function ({ commit }, data) {
      if (data?.token) {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, data.token);
      }
      this.state.user = parseJwt(process.env.VUE_APP_TOKEN_NAME);
      return this.state.user;
    },
    getLocale: async function ({ commit }) {
      let userLang = localStorage.getItem(process.env.VUE_APP_LANG_KEY);
      let mylang = userLang ? userLang.substring(0, 2) : navigator.language.substring(0, 2);
      const locale = await require(`../assets/langs/${mylang}.json`);
      this.state.locale = { ...this.state.locale, ...locale };
    },
    nativeEvents: function ({ state }) {
      document.addEventListener("click", function (e) {
        const link = e.target.getAttribute("link");
        if (e.target && link) {
          router.push(link);
        }
      });
      window.onresize = ({ target }) => {
        state.screen = target.screen;
      };
      window.addEventListener("message", (event) => {
        if (event.data.msg === "updatefound") {
          this.state.updates = true;
        }
      });
    },
  },
  getters: {
    locale: (state) => state.locale,
    user: (state) => state.user,
    updates: async (state) => await state.updates,
    screen: (state) => state.screen,
  },
});

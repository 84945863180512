const routes = [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/",
        component: () => import("../components/home/HomeContainer.vue"),
      },
      {
        path: "/services",
        component: () => import("../components/services/ServicesContainer.vue"),
      },
      {
        path: "/about",
        component: () => import("../components/about/AboutContainer.vue"),
      },
      {
        path: "/jobs",
        component: () => import("../components/jobs/JobsContainer.vue"),
      },
      {
        path: "/contact",
        component: () => import("../components/contact/ContactContainer.vue"),
      },
    ],
  },
  {
    path: "/terms",
    component: () => import("../views/Page.vue"),
  },
  {
    path: "/privacy",
    component: () => import("../views/Page.vue"),
  },
];

export default routes;

export default {
  validateForm: function(formData, options) {
    const _this = this;
    const fmInputs = document.querySelectorAll(`${formData} input, ${formData} select`);
    const fmOptions = document.querySelectorAll(`${formData} input[type="radio"]`);
    [].forEach.call(fmInputs, function(el) {
      if (el.hasAttribute("required")) {
        _this.validateInput(el, options);
      }
    });
    [].forEach.call(fmOptions, function(el) {
      if (el.hasAttribute("required")) {
        _this.validateOption(el, options);
      }
    });
    const dataErrors = document.querySelectorAll(`${formData} .data-error`);
    if (dataErrors.length) {
      return false;
    } else {
      return true;
    }
  },
  validateCheckboxList: function(selector) {
    let elements = document.querySelectorAll(selector);
    elements = [...elements];
    const someChecked = elements.some((el) => el.checked);
    return someChecked;
  },
  validateOption: function(el, options) {
    const _this = this;
    let isSelected = false;
    const myElm = typeof el === "string" ? document.querySelector(el) : typeof el === "object" ? el : el;
    const _inputs =
      typeof el === "string" ? document.querySelectorAll(el + " input") : typeof el === "object" ? el.getElementsByTagName("input") : el;
    [].forEach.call(_inputs, function(_el) {
      if (_el.checked === true) {
        isSelected = true;
      }
    });
    isSelected ? _this.successInput(myElm ? myElm : el, options) : _this.errorInput(myElm ? myElm : el, options);
  },
  validEmail: function(email) {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    } else {
      return false;
    }
  },
  validPhone: function(phone) {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  },
  validNumber: function(value) {
    let numbers = /^[0-9]+$/;
    if (value.match(numbers)) {
      return true;
    } else {
      return false;
    }
  },
  validateInput: function(el, options) {
    const _this = this;
    const fileExts = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    const _el = el.srcElement ? el.srcElement : typeof el === "object" ? el : el.srcElement;
    const _val = _el.value ? _el.value.trim() : "";
    if (_el.type === "text" || _el.type === "date" || _el.type === "textarea" || _el.type === "password") {
      _val ? _this.successInput(_el, options) : _this.errorInput(_el, options);
    } else if (_el.type === "select-one") {
      _val ? _this.successInput(_el, options) : _this.errorInput(_el, options);
    } else if (_el.type === "email") {
      _this.validEmail(_val) ? _this.successInput(_el, options) : _this.errorInput(_el, options);
    } else if (_el.type === "tel") {
      _this.validPhone(_val) ? _this.successInput(_el, options) : _this.errorInput(_el, options);
    } else if (_el.type === "number") {
      _this.validNumber(_val) ? _this.successInput(_el, options) : _this.errorInput(_el, options);
    } else if (_el.type === "file") {
      _el.files.length && fileExts.exec(_val) ? _this.successInput(_el, options) : _this.errorInput(_el, options);
    }
  },
  errorInput: function(el, options) {
    let placeholder = el.getAttribute("placeholder");
    el.classList.add("data-error");
    if (options?.tooltip !== false) {
      el.nextElementSibling ? el.nextElementSibling.remove() : "";
      el.insertAdjacentHTML("afterend", '<div class="tooltip data-error">' + placeholder + "</div>");
    }
  },
  successInput: function(el, options) {
    el.classList.remove("data-error");
    if (options?.tooltip !== false) {
      el.nextElementSibling ? el.nextElementSibling.remove() : "";
    }
  },
  isEmptyObj: function(obj) {
    if (obj === [] || obj === null || obj === undefined || obj === {} || obj === "[]") {
      return true;
    }
  },
};

<template>
  <div class="update-available" v-if="$locale && $updates">
    <div class="update-available-content" v-if="$locale.updates" v-bind="cacheClean()">
      <div class="update-available-card">
        <div class="logo"><AppLogo /></div>
        <div class="title">
          <h1>{{ $locale.updates.title }}</h1>
        </div>
        <div class="message">
          <p>{{ $locale.updates.message }}</p>
        </div>
        <div class="options">
          <a @click="refresApp">{{ $locale.updates.button }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from "./AppLogo.vue";
export default {
  components: { AppLogo },
  methods: {
    elements: function (elm) {
      const elms = {
        body: document.querySelector("body"),
        card: document.querySelector(".update-available-card"),
        custom: document.querySelector(elm),
      };
      return elms[elm] || elms["custom"];
    },
    refresApp: function () {
      this.elements("body").classList.add("loading");
      this.elements("card").classList.add("hidden");
      this.cacheClean();
      top.location.reload();
    },
    cacheClean: function () {
      this.elements("body").classList.add("lock");
      window?.caches?.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-available {
  @include Fixed();
  z-index: 999;
  background-color: hsla(0, 0%, 98%, 0.5);
  backdrop-filter: saturate(1.8) blur(20px);
  user-select: none;
  &-content {
    @include Flex(inherit, center, center);
    height: 100%;
    overflow-y: auto;
    padding: $mpadding;
  }
  &-card {
    background: rgb(0, 0, 0);
    color: #fff;
    width: 100%;
    max-width: 320px;
    border-radius: $mpadding;
    text-align: center;
    overflow: hidden;
    box-shadow: $dshadow;
    .logo {
      pointer-events: none;
      padding: $mpadding * 2 $mpadding * 2 $mpadding $mpadding * 2;
    }
    .title {
      padding: 0 $mpadding * 2 $mpadding $mpadding * 2;
      font-size: 110%;
    }
    .message {
      padding: 0 $mpadding * 2 $mpadding $mpadding * 2;
    }
    .options {
      a {
        display: block;
        padding: $mpadding;
        border-top: solid 1px $alto;
        font-size: 115%;
        cursor: pointer;
        font-weight: bold;
        &:active {
          background: rgb(31, 31, 31);
        }
      }
    }
  }
}
</style>

import dictionary from "./dictionary";
export default {
  setSrc: (data) => {
    let el = document.querySelector(data.el);
    el.src = data.src;

    let elems = document.querySelectorAll(".product-view-img-wrap .product-view-gallery-img-card");
    [].forEach.call(elems, function(el) {
      el.classList.remove("selected");
    });

    let selelems = document.querySelectorAll(`.product-view-img-wrap .` + data.mrk);
    [].forEach.call(selelems, function(el) {
      el.classList.add("selected");
    });
  },
  toggleClass: (data) => {
    let el = document.querySelector(data.el);
    let clss = data.class;
    el.classList.toggle(clss);
  },
  dateLocaleFormat: (date) => {
    date = new Date(date);
    const ulang = localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleDateString(ulang, options);
  },
  timeAMPM: (time) => {
    const myTime = time.split(":");
    let hours = myTime[0];
    let minutes = myTime[1];
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
  monthName: function(date) {
    date = new Date(date);
    const ulang = localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      month: "long",
    };
    return date.toLocaleDateString(ulang, options);
  },
  toInputDate: function(date) {
    if (date && date.includes("T")) {
      date = date.split("T")[0];
    }
    return date;
  },
  toInputDateTime: function(date) {
    if (date && date.includes("Z")) {
      date = date.split("Z")[0];
    }
    return date;
  },
  yearsDiff: (d1, d2) => {
    let yearsDiff = new Date(new Date(d2) - new Date(d1)).getFullYear() - 1970;
    return yearsDiff;
  },
  getCurrentYear: function() {
    const d = new Date();
    return d.getFullYear();
  },
  getMainImg: (data) => {
    var found = data.find(function(element) {
      if (element.isMain === true) return element;
    });
    if (found) {
      return found.link;
    }
  },
  getMainlang: (data, lang) => {
    var found = data.find(function(element) {
      if (element.languageId === lang) return element;
    });
    if (found) {
      return found;
    }
  },
  validEmail: (email) => {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    } else {
      return false;
    }
  },
  validPhone: (phone) => {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  },
  getShort: (text, limit) => {
    let ellipsis = text.length > limit ? "..." : "";
    text = text.replace(/(<([^>]+)>)/gi, " ");
    return typeof text !== "string" ? text : text.substring(0, limit) + ellipsis;
  },
  linkIsActive: ($route, input) => {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some((path) => {
      return $route.fullPath.indexOf(path) === 0; // current path starts with this path string
    });
  },
  linkInclude: ($route, input) => {
    if ($route && input) {
      if ($route.fullPath.includes(input)) {
        return true;
      }
    }
    return false;
  },
  scrollToEl: (data) => {
    let el = document.querySelector(data.el);
    let y = el.offsetTop + -data.topgap;
    window.scroll({ top: y, behavior: data.behavior });
  },
  fastID: () => {
    function chr4() {
      return Math.random()
        .toString(16)
        .slice(-4);
    }
    return chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4();
  },
  hasSome: function(data1, data2) {
    if (typeof data1 === "object" && typeof data2 === "object") {
      return data1.some((some) => data2.includes(some));
    }
  },
  dictionary: {
    ...dictionary.dictionary,
  },
  loading: (elm) => {
    const mylmnt = document.querySelector(elm);
    if (mylmnt) mylmnt.classList.add("loading");
  },
  unloading: (elm) => {
    const mylmnt = document.querySelector(elm);
    if (mylmnt) mylmnt.classList.remove("loading");
  },
  objectIndex: (arr, key, val) => {
    return arr.findIndex((obj) => obj[key] === val);
  },
  setAttribute: (element, attribute, value) => {
    const selelems = document.querySelectorAll(element);
    [].forEach.call(selelems, function(el) {
      el.setAttribute(attribute, value);
    });
  },
  replaceAll: function(string, search, replace) {
    return string.split(search).join(replace);
  },
  getApp: function(appKey) {
    const apps = this.dictionary.apps_icons;
    const app = apps.filter((a) => a.key === appKey);
    return app[0];
  },
  getHost: function(url) {
    const theurl = new URL(url);
    return theurl.origin;
  },
  removeCharAt: function(pos, string) {
    if (pos && string) {
      string = string.substring(pos);
    }
    return string;
  },
  useSemantic: function(htmlString) {
    const htmlData = document.createElement("div");
    htmlData.innerHTML = htmlString;
    const elements = htmlData.querySelectorAll("*");
    elements.forEach((element) => element.classList.add("semantic"));
    const html = Array.prototype.reduce.call(
      elements,
      function(html, node, count) {
        count++;
        if (count === 1) {
          html = node.outerHTML || node.nodeValue;
        }
        return html;
      },
      ""
    );
    return html;
  },
};

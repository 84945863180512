import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import validate from "./assets/js/validate";
import validateOG from "./assets/js/validateOG";
import global from "./assets/js/global";
import api from "./api/";
import ialert from "./plugins/ialert/";
import iconic from "./plugins/iconic/";
import Swal from "sweetalert2";
import { localeLang } from "./mixins/localeLang";
import Preload from "./components/global/preload.vue";
import Spinner from "./components/global/Spinner.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "@/assets/styles/main.scss";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.prototype.$global = global;
Vue.prototype.$store = store;
Vue.prototype.$validate = validate;
Vue.prototype.$validateOG = validateOG;
Vue.prototype.$api = api;
Vue.prototype.$Swal = Swal;

Vue.mixin(localeLang);
Vue.use(ialert);
Vue.use(iconic);
Vue.use(VueMeta);

//Global Components
Vue.component("Preload", Preload);
Vue.component("Spinner", Spinner);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export const localeLang = {
  computed: {
    $locale() {
      return this.$store.getters.locale;
    },
    $user() {
      return this.$store.getters.usuer;
    },
    $loaded() {
      return this.$store.state.viewLoaded;
    },
    $screen() {
      return this.$store.state.screen;
    },
    $updates() {
      return this.$store.state.updates;
    },
  },
};

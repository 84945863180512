import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import HomeRoutes from "./HomeRoutes";
import ErrorRoutes from "./ErrorRoutes";

Vue.use(VueRouter);

const routes = [...HomeRoutes, ...ErrorRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        x: 0,
        y: 0,
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return {
        x: 0,
        y: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;

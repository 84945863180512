/*
@Created By
@Luis Carlos Hurtado
*/
import iconic from "./iconic";
import "./iconic.scss";
export default {
  install(Vue, options) {
    Vue.component("iconic", iconic);
  },
};

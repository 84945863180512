export default {
  validateForm(formSelector) {
    const inputs = document.querySelectorAll(`${formSelector} label[needed] input`);
    inputs.forEach((input) => {
      const type = ["text", "number"].includes(input.type) ? input.dataset.type : input.type;
      this[type](input);
    });
    const errors = document.querySelectorAll(`${formSelector} [inputError]`);
    return !errors.length;
  },
  name(el) {
    const isValid = el.value.trim() ? new RegExp("^[A-ZÁÉÍÓÚÑ ]+$", "i").test(el.value) : false;
    isValid ? this.success(el) : this.failure(el);
  },
  any(el) {
    el.value.trim() ? this.success(el) : this.failure(el);
  },
  email(el) {
    const isValid = new RegExp(
      "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
    ).test(el.value);
    isValid ? this.success(el) : this.failure(el);
  },
  checkbox(el) {
    el.checked ? this.success(el, "checkbox") : this.failure(el, "checkbox");
  },
  number(el) {
    const type = el.dataset.type;
    this[type](el);
  },
  file(el) {
    if (!el.files.length) return this.failure(el);
    const extensions = el.accept.split(",");
    const files = [];
    [...el.files].forEach((file) => {
      const fileExt = `.${el.value.split(".").pop()}`;
      const validType = extensions.includes(file.type);
      const validExt = extensions.includes(fileExt);
      validExt || validType ? files.push({ name: file.name, valid: true }) : files.push({ name: file.name, valid: false });
    });
    files.every((file) => file.valid) ? this.success(el) : this.failure(el);
  },
  phone(el) {
    const isValid = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-s./0-9]*$/.test(el.value);
    isValid ? this.success(el) : this.failure(el);
  },
  success(el, type) {
    if (type === "checkbox") {
      const checkbox = document.querySelector("label [checkbox]");
      checkbox.lastChild.hasAttribute("inputError") ? checkbox.lastChild.remove() : "";
      el.classList.remove("formError");
      return;
    }

    el.classList.remove("formError");
    el.nextElementSibling ? el.nextElementSibling.remove() : "";
  },
  failure(el, type) {
    if ([...el.classList].includes("formError")) return;
    const placeholder = el.getAttribute("placeholder");
    if (type === "checkbox") {
      document.querySelector("label [checkbox]").insertAdjacentHTML("beforeend", `<div inputError>${placeholder}</div>`);
      el.classList.add("formError");
      return;
    }
    el.classList.add("formError");
    el.insertAdjacentHTML("afterend", `<div inputError>${placeholder}</div>`);
  },
};
